export const scrollToElement = (
    className,
    index,
    smooth = false
) => {
    const nodeCollection = document.querySelectorAll(`.${className}`);
    const lastNodeCollectionElement = nodeCollection?.[nodeCollection.length - 1];
    lastNodeCollectionElement?.scrollIntoView(
        smooth &&
        (index === 0
            ? { behavior: 'smooth', block: 'start' }
            : { behavior: 'smooth' })
    );
};