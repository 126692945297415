import React, { useMemo, memo } from 'react';
import { Box, AvatarGroup, Typography } from '@mui/material';

import styled from '@emotion/styled';

import { User } from '../../common/User/User';

import { ReactComponent as LogoIcon } from '../../assets/icons/logo.svg';
import { ReactComponent as HideChatIcon } from '../../assets/icons/line.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';

export const ChatHeaderStyled = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  height: '64px',
  color: theme.palette.header.text,
  backgroundColor: theme.palette.header.main,
  '& > *': {
    flexShrink: 0,
  },
}));

export const ChatHeader = memo(
  ({
    managersList,
    isDialogActive,
    logotype,
    name,
    showCloseButton,
    onClose,
    onCancel,
  }) => {
    const managersNames = useMemo(
      () =>
        managersList?.reduce(
          (acc, manager, index, arr) =>
            index < 2
              ? acc +
                (index === arr.length - 1 ? manager.name : `${manager.name}, `)
              : arr.length - 1 === index
              ? `${acc} и еще ${arr.length - 2}`
              : acc,
          ''
        ),
      [managersList]
    );

    return (
      <ChatHeaderStyled>
        {!!managersList?.length ? (
          <>
            <AvatarGroup max={3}>
              {managersList?.map((manager) => (
                <User
                  key={manager.id}
                  src={manager?.avatar?.file}
                  alt={manager?.name}
                />
              ))}
            </AvatarGroup>
            <Typography
              sx={(theme) => ({
                marginLeft: theme.spacing(2),
                color: theme.palette.header.text,
              })}
            >
              {managersNames}
            </Typography>
          </>
        ) : logotype?.file ? (
          <>
            <Box
              component={'img'}
              sx={{
                width: '36px',
                height: '36px',
                mr: 2,
                borderRadius: '50%',
                objectFit: 'cover',
                objectPosition: 'center',
              }}
              width="36"
              height="36"
              src={logotype?.file}
              alt={logotype?.fileName}
              loading="lazy"
            />
            {name}
          </>
        ) : (
          <>
            <Box
              component={LogoIcon}
              sx={{
                borderRadius: '50%',
                mr: 2,
              }}
            />
            StreamTelecom{' '}
          </>
        )}
        <Box sx={{ flexGrow: 1 }} />
        <Box
          onClick={onClose}
          component={HideChatIcon}
          sx={{
            ml: 'auto',
            mr: 0.9,
            cursor: 'pointer',
          }}
        />
        {showCloseButton && isDialogActive && (
          <Box
            onClick={onCancel}
            component={CloseIcon}
            sx={{
              ml: '14px',
              mr: 0.9,
              cursor: 'pointer',
            }}
          />
        )}
      </ChatHeaderStyled>
    );
  }
);
