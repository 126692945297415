export const COLORS = {
    PRIMARY: '#196CA7',
    PRIMARY_LIGHT: '#DAECF9',
    SUCCESS: '#0B0',
    SUCCESS_LIGHT: '#6CB351',
    ERROR: '#AF1F41',
    ERROR_LIGHT: '#FD4D5C',
    ERROR_ALTERNATE: '#F78E98',
    WARNING: '#EC7A3B',
    WARNING_LIGHT: '#FEDC5C',
    DARK_COLOR: '#363F48',
    BROWN: '#595F6B',
    DARK_GREY: '#9197A5',
    LIGTH_GREY: '#E7E8EC',
    GREY: '#F5F6FA',
    WHITE: '#fff',
}