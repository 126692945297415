import React, { useEffect, useState, useRef, useCallback, useLayoutEffect } from 'react';
import ReactDOM from 'react-dom/client';

import io from 'socket.io-client';
import { detectIncognito } from "detectincognitojs";

import { App } from './App';
import reportWebVitals from './reportWebVitals';

import useHttp from './hooks/useHttp';
import useSetIframeStyles from './hooks/useSetIframeStyles';

import { getAthorisationSearchParams } from './utils/getAthorisationSearchParams';

import { base64Audio } from './constants';

const authorisationSearchParams = getAthorisationSearchParams();

const widgetId = localStorage.getItem('widgetId');

export let socket = null;

const audio = new Audio(base64Audio);
window.messageAudio = audio;

const Page = () => {
  const [isTokenChanged, setIsTokenChanged] = useState(false);
  const [authorisationData, authorisationRequest, error] = useHttp(
    `widget/${widgetId}?${authorisationSearchParams}`
  );
  const [isWidgetBlocked, setIsWidgetBlocked] = useState(false);
  const [token, setToken] = useState(null);

  useSetIframeStyles({
    'display': isWidgetBlocked ? 'none' : 'block',
  });

  const fetchAuthorisationData = useCallback(
    () => authorisationRequest({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }), []);

  useLayoutEffect(() => {
    let isIncognito = null;
    // if (navigator.storage && navigator.storage.estimate) {
    //   navigator.storage.estimate().then(estimate => {
    //     if (estimate.quota < 1200000000) {
    //       isIncognito = true;
    //     } else {
    //       isIncognito = false;
    //     }
    //     localStorage.setItem('isIncognito', isIncognito);
    //   });
    // }
    detectIncognito().then((result) => {
      console.log(Number(result.isPrivate));
      localStorage.setItem('isIncognito', Number(result.isPrivate));
    });
  }, []);

  useEffect(() => {
    if (!isWidgetBlocked) {
      fetchAuthorisationData();
      setIsWidgetBlocked(false);
    } else {
      setIsWidgetBlocked(true);
    }
  }, [isWidgetBlocked, error]);

  useEffect(() => {
    if (error) {
      setIsWidgetBlocked(true);
    }
  }, [error]);

  useEffect(() => {
    if (authorisationData?.data?.token) {

      localStorage.setItem('token', authorisationData?.data?.token);
      localStorage.setItem('expires', authorisationData?.data?.expires);
      localStorage.setItem('refresh', authorisationData?.data?.refresh);
      localStorage.setItem('refresh_expires', authorisationData?.data?.refresh_expires);
      localStorage.setItem('sc_visitor', authorisationData?.data?.id);
      setToken(authorisationData?.data?.token);
    };

  }, [authorisationData?.data?.token]);

  useEffect(() => {
    if (!token) {
      return;
    }
    socket = io(process.env.REACT_APP_SOCKET_URL, {
      query: {
        token,
      },
      reconnection: true,
      reconnectionDelay: 500,
    });

    const visitorStatusHandler = (data) => {
      if (data.status === 'blocked') {
        setIsWidgetBlocked(true);
      } else {
        setIsWidgetBlocked(false);
        fetchAuthorisationData();
      }
    };
    socket.on('visitor.status', visitorStatusHandler);
    return () => {
      socket.off('visitor.status', visitorStatusHandler);
      if (socket.connected) {
        socket.disconnect();
      }
    };
  }, [token, isTokenChanged]);

  return <App widgetId={widgetId} isWidgetBlocked={isWidgetBlocked} token={token} setIsTokenChanged={setIsTokenChanged} />

}

const root = ReactDOM.createRoot(document.getElementById('widget'));
root.render(
  <Page />
);

reportWebVitals();
