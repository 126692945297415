export const translates = {
    en: {
        'apply_btn': 'Yes',
        'cancel_btn': 'No',
        'close': 'Close',
        'cancel': 'Cancel',
        'send': 'Send',

        'complete_askings': 'Has your issue been resolved?',
        'cancel_dialog': 'Are you sure you want to cancel the conversation?',

        'give_us_your_data': 'Please provide your data',
        'first_name': 'First name',
        'last_name': 'Last name',
        'father_name': 'Father name',
        'phone': 'Phone',
        'email': 'E-mail',

        'write_us_in_messenger': 'Write us in messenger',
        'your_message': 'Your message...',

        'footer_text': 'Chat provided by StreamTelecom',
        'writing': 'Is typing',

        'today': 'Today',
        'show_history': 'Show history',
        'no_free_operators': 'Unfortunately, there are no free operators now. Wait for connection.',
        'fill': 'Fill',
    },
    ru: {
        'apply_btn': 'Да',
        'cancel_btn': 'Нет',
        'close': 'Завершить',
        'cancel': 'Отмена',
        'send': 'Отправить',

        'complete_askings': 'Был ли решен ваш вопрос?',
        'cancel_dialog': 'Завершить диалог?',

        'give_us_your_data': 'Предоставьте свои данные',
        'first_name': 'Имя',
        'last_name': 'Фамилия',
        'father_name': 'Отчество',
        'phone': 'Телефон',
        'email': 'E-mail',

        'write_us_in_messenger': 'Напишите нам в мессенджер',
        'your_message': 'Ваше сообщение...',

        'footer_text': 'Чат предоставлен компанией StreamTelecom',
        'writing': 'Печатает',

        'today': 'Сегодня',
        'show_history': 'Показать историю',
        'no_free_operators': 'К сожалению сейчас нет свободных операторов. Ожидайте подключения.',
        'fill': 'Заполнить',
    },
    uk: {
        'apply_btn': 'Так',
        'cancel_btn': 'Ні',
        'close': 'Закрити',
        'cancel': 'Скасувати',
        'send': 'Відправити',

        'complete_askings': 'Чи було вирішено ваше питання?',
        'cancel_dialog': 'Завершити діалог?',

        'give_us_your_data': 'Будь ласка, надайте свої дані',
        'first_name': "Ім'я",
        'last_name': 'Прізвище',
        'father_name': 'По батькові',
        'phone': 'Телефон',
        'email': 'E-mail',

        'write_us_in_messenger': 'Напишіть нам в месенджер',
        'your_message': 'Ваше повідомлення...',

        'footer_text': 'Чат наданий компанією StreamTelecom',
        'writing': 'Пише',

        'today': 'Сьогодні',
        'show_history': 'Показати історію',
        'no_free_operators': 'На жаль, зараз немає вільних операторів. Очікуйте підключення.',
        'fill': 'Заповнити',
    },
};