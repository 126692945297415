import { createTheme } from '@mui/material/styles';

import { COLORS } from './colors';

let themeBase = createTheme({
    spacing: 7,
    typography: {
        // fontFamily: 'Times, sans-serif',
        fontSize: 14,
        body1: {
            fontSize: 14,
            fontWeight: 400,
        },
        subtitle1: {
            fontSize: 12,
            fontWeight: 400,
        },
        subtitle2: {
            fontSize: 10,
            fontWeight: 400,
        },
        h1: {
            fontSize: 24,
            fontWeight: 700,
        },
        h2: {
            fontSize: 20,
            fontWeight: 700,
        },
        h3: {
            fontSize: 18,
            fontWeight: 700,
        },
        h4: {
            fontSize: 16,
            fontWeight: 700,
        },
        h5: {
            fontSize: 14,
            fontWeight: 700,
        },
        h6: {
            fontSize: 12,
            fontWeight: 700,
        },
        button: {
            fontSize: 14,
            fontWeight: 700,
        },

    },
    palette: {
        background: {
            default: "none"
        },
        primary: {
            main: '#feea00',
            light: '#ffa',
            text: COLORS.WHITE,
            background: COLORS.WHITE,
        },
        success: {
            main: COLORS.SUCCESS,
            light: COLORS.SUCCESS_LIGHT,
        },
        error: {
            main: COLORS.ERROR,
            light: COLORS.ERROR_LIGHT,
            alternate: COLORS.ERROR_ALTERNATE,
        },
        warning: {
            main: COLORS.WARNING,
            light: COLORS.WARNING_LIGHT,
        },
        text: {
            dark: COLORS.DARK_COLOR,
            light: COLORS.WHITE,
        },
        brown: {
            main: COLORS.BROWN,
        },
        wtite: {
            main: COLORS.WHITE,
        },
        grey: {
            main: COLORS.GREY,
            light: COLORS.LIGTH_GREY,
            dark: COLORS.DARK_COLOR,
            contrastText: COLORS.DARK_COLOR,
            derken: COLORS.DARK_GREY,
        },
        header: {
            main: COLORS.SUCCESS,
            light: COLORS.PRIMARY_LIGHT,
            text: COLORS.WHITE,
        },
        button: {
            main: COLORS.SUCCESS,
            icon: COLORS.WHITE,
        },
        window: {
            background: COLORS.WHITE,
            content: COLORS.DARK_COLOR,
        },

    },
});

const theme = createTheme({
    ...themeBase,
    shadows: ["none"],
    breakpoints: {
        values: {
            sm: 360,
        },
    },
    mixins: {
        toolbar: {
            minHeight: '48px',
        },
    },
    components: {
        MuiStack: {
            defaultProps: {
                useFlexGap: true,
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    height: '40px',
                    padding: themeBase.spacing(1.45),

                },

            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: themeBase.palette.text.dark,
                },
            },
        },
        MuiBadge: {
            styleOverrides: {
                badge: {
                    width: '9px',
                    height: '9px',
                    backgroundColor: themeBase.palette.success.main,
                    border: `1px solid ${themeBase.palette.wtite.main}`,
                },
            },
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiButton: {
            variants: [
                {
                    props: { variant: 'contained', color: 'grey' },
                    style: {
                        backgroundColor: themeBase.palette.grey.light,
                        '&:hover': {
                            backgroundColor: themeBase.palette.grey.main,
                        },
                    },
                },

            ],
            styleOverrides: {
                root: {
                    textTransform: 'initial',
                }
            }
        },
        MuiAvatar: {
            defaultProps: {
                sx: {
                    width: '36px',
                    height: '36px',
                },
            },
        },
        MuiAvatarGroup: {
            defaultProps: {
                sx: {
                    ml: 1,
                    '.MuiAvatar-root': {
                        border: 'none',
                    },
                    '.MuiBadge-badge': {
                        top: '10%',
                        left: '-10%',
                        borderRadius: '50%',
                    },
                    '.MuiBadge-root': {
                        ':first-of-type': {
                            zIndex: 2,
                        },
                    },
                }
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    padding: '11px 25px',
                    height: '40px',
                    borderRadius: '50px',
                    borderColor: themeBase.palette.brown.main,
                    fontSize: '14px',
                    cursor: 'pointer',
                },

            },
            defaultProps: {
                sx: {
                    '.MuiChip-label': {
                        padding: '0',
                    }
                }
            },
        },
    },
});

export default theme;
