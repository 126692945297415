import React from 'react';

import { Box, Typography, Chip, Button } from '@mui/material';

export const Details = ({ title, children }) => {
  return (
    <Box
      sx={{
        color: 'primary.text',

        position: 'relative',
        'summary::-webkit-details-marker': {
          content: 'none',
        },
        'summary::marker': {
          content: 'none',
        },
        'summary::before': {
          content: '""',
          position: 'absolute',
          top: '50%',
          left: '16px',
          width: 0,
          height: 0,
          transform: 'translateY(-2px)',

          border: '6px solid transparent',
          borderTop: 0,
          borderBottom: '9px solid currentColor',
          cursor: 'pointer',
        },
        '&[open] > summary::before': {
          border: '6px solid transparent',
          borderRight: 0,
          borderLeft: '9px solid currentColor',
          transform: 'translateY(-50%)',
        },
        '&[open] .details-header': {
          display: 'none',
        },
      }}
      component={'details'}
    >
      <Box component={'summary'}>
        <Typography
          className="details-header"
          sx={(theme) => ({
            display: 'flex',
            p: '18px 20px 12px 46px',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontWeight: '500',
            lineHeight: 1,
            color: theme.palette.primary.text,
          })}
        >
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          p: '9px 20px 8px 50px',
          borderTop: '1px solid #E6E6E6',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
