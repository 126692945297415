
const names = {
    ua: {
        bytes: 'байт',
        kb: 'КБ',
        mb: 'МБ',
        gb: 'ГБ'
    },
    ru: {
        bytes: 'байт',
        kb: 'КБ',
        mb: 'МБ',
        gb: 'ГБ'
    },
    en: {
        bytes: 'bytes',
        kb: 'KB',
        mb: 'MB',
        gb: 'GB'
    }
}

export const convertBytes = (bytes, locale = 'ru') => {
    let result;

    switch (true) {
        case bytes < 1024:
            result = `${bytes} ${names[locale].bytes}`;
            break;
        case bytes < 1048576:
            result = `${(bytes / 1024).toFixed(0)} ${names[locale].kb}`;
            break;
        case bytes < 1073741824:
            result = `${(bytes / 1048576).toFixed(2)} ${names[locale].mb}`;
            break;
        default:
            result = `${(bytes / 1073741824).toFixed(2)} ${names[locale].gb}`;
            break;
    }

    return result;
};