import {
    useEffect,
} from 'react';

const useSetIframeStyles = (styles) => {

    useEffect(() => {
        const iframe = window.parent.document.querySelector('iframe');
        if (!iframe) return;
        Object.entries(styles).forEach(([key, value]) => {
            iframe.style.setProperty(key, value);
        });
    }, [styles]);

}

export default useSetIframeStyles;
