import React, { useCallback } from 'react';
import { Box, Avatar, Typography } from '@mui/material';
import styled from '@emotion/styled';

import { User } from '../../common/User/User';

import { convertBytes } from '../../utils/convertBytes';
import { getTime } from '../../utils/getTime';
import { translates } from '../../constants/translates';

import { ReactComponent as FileIcon } from '../../assets/icons/file.svg';

const MessageStyled = styled(Box)(({ theme, isMine, sx }) => ({
  position: 'relative',
  display: 'flex',
  maxWidth: '80%',
  minWidth: '100px',
  width: 'fit-content',
  flexDirection: 'column',
  padding: `${theme.spacing(0.86)} ${theme.spacing(1.44)} ${theme.spacing(
    2.2
  )}`,
  marginLeft: isMine ? 'auto' : '0',
  color: `${theme.palette.primary.text}`,
  borderRadius: '5px',
  wordBreak: 'break-word',
  '&:not(:last-child)': {
    marginBottom: theme.spacing(0.58),
  },
  'p:not(.file-size)': {
    margin: 0,
  },
  'ul, ol': {
    paddingLeft: '20px',
  },
  ...sx(theme),
}));

export const Message = ({
  prevMessage,
  className,
  message,
  defaultLang,
  isMine = false,
  isSingle = false,
}) => {
  const { created_at, updated_at } = message;

  const openFileHandler = useCallback(
    () => window.open(message?.attachments[0]?.url),
    [message]
  );

  const textWithBr = message?.text?.replace(/\n/g, '<br />');

  const messageDate = new Date(created_at);
  const today = new Date();
  const isToday =
    messageDate.getDate() === today.getDate() &&
    messageDate.getMonth() === today.getMonth() &&
    messageDate.getFullYear() === today.getFullYear();
  return (
    <>
      {new Date(created_at).getDay() !==
        new Date(prevMessage?.created_at).getDay() && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: '12px',
            mb: '12px',
            color: 'grey.derken',
            textDecoration: 'underline',
          }}
        >
          {isToday
            ? translates[defaultLang].today
            : new Date(created_at).toLocaleString(defaultLang, {
                day: 'numeric',
                month: 'long',
              })}
        </Box>
      )}
      <MessageStyled
        sx={(theme) => ({
          marginLeft: isMine ? 'auto' : '0',
          bgcolor: isMine ? 'primary.main' : 'grey.main',
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: '-10px',
            left: '20px',
            width: 0,
            height: 0,
            borderLeft: '14px solid transparent',
            borderRight: '14px solid transparent',
            borderTop: '16px solid transparent',
            borderTopColor: !isMine && !isSingle && theme.palette.grey.main,
          },
        })}
        className={className}
      >
        {message?.text ? (
          <div
            dangerouslySetInnerHTML={{
              __html: textWithBr,
            }}
          />
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Avatar
              onClick={openFileHandler}
              sx={{
                width: '44px',
                height: '44px',
                bgcolor: 'grey.dark',
                cursor: 'pointer',
              }}
            >
              <FileIcon />
            </Avatar>
            <Box>
              <Typography
                color="text"
                component={'div'}
                variant="body2"
                sx={{ ml: 1 }}
              >
                {message?.attachments[0]?.file?.name}
              </Typography>
              <Typography
                color="grey.derken"
                variant="body2"
                sx={{ ml: 1, width: '100%' }}
                typography="subtitle1"
                className="file-size"
              >
                {convertBytes(message?.attachments[0]?.file?.size)}
              </Typography>
            </Box>
          </Box>
        )}
        <Box
          sx={{
            position: 'absolute',
            right: '8px',
            bottom: '4px',
            ml: 'auto',
            color: 'grey.derken',
          }}
          typography="subtitle2"
        >
          {new Date(created_at).toLocaleString() ===
          new Date(updated_at).toLocaleString()
            ? getTime(created_at)
            : `ред. ${getTime(updated_at)}`}
        </Box>
      </MessageStyled>
      {!isSingle && !isMine && (
        <User
          isOnline={false}
          size="sm"
          sx={{ mt: 1, mb: 1 }}
          alt={message?.user?.name}
          src={message?.user?.avatar}
        />
      )}
    </>
  );
};
