
// let params = new URLSearchParams({
//     domain: https://${domain?.domain} }); 
//     let paramsToString = params.toString();

// }

const getBrowser = () => {
    const userAgent = navigator.userAgent;
    let browser = "Unknown";

    if (userAgent.indexOf("Firefox") > -1) {
        browser = "Firefox";
    } else if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
        browser = "Opera";
    } else if (userAgent.indexOf("Trident") > -1) {
        browser = "Internet Explorer";
    } else if (userAgent.indexOf("Edge") > -1) {
        browser = "Edge";
    } else if (userAgent.indexOf("Chrome") > -1) {
        browser = "Chrome";
    } else if (userAgent.indexOf("Safari") > -1) {
        browser = "Safari";
    }

    return browser;
}

const getCookie = (name) => {
    const matches = document.cookie.match(new RegExp(
        // eslint-disable-next-line no-useless-escape
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));

    return matches ? decodeURIComponent(matches[1]) : undefined;
}

export const getAthorisationSearchParams = () => new URLSearchParams(({
    current_page_name: window.parent.document.title,
    current_page_href: window.parent.window.location.href,
    device: navigator.platform,
    browser: getBrowser(),
    ga: getCookie('_ga') || null,
    sc_visitor: localStorage.getItem('sc_visitor'),
    isIncognito: localStorage.getItem('isIncognito'),
    // ga: 'GA1.1.179498480.1699343796'
}));
