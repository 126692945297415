import { useEffect, useCallback } from 'react';
const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const refetchTokens = async () => {
    const expires = localStorage.getItem('expires');
    const refresh = localStorage.getItem('refresh');
    const widgetId = localStorage.getItem('widgetId');

    // const time = Date.now() + 1000 * 50 * 1;

    // if (Date.parse(expires) < time) {
    const response = await fetch(`${BASE_URL}/widget/${widgetId}/refresh`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${refresh}`,
        },
    });
    if (response.status == 500) {
        window.location.reload();
    }
    if (!response.ok) {
        console.log(response);
        const error = await response.json();
        throw new Error(error?.message);
    }
    const data = await response.json();
    localStorage.setItem('token', data?.data?.token);
    localStorage.setItem('expires', data?.data?.expires);
    localStorage.setItem('refresh', data?.data?.refresh);
    localStorage.setItem('refresh_expires', data?.data?.refresh_expires);
    // return await data?.data?.token;
    // }
}
// export const refetchTokens = async () => {
//     const expires = localStorage.getItem('expires');
//     const refresh = localStorage.getItem('refresh');
//     const widgetId = localStorage.getItem('widgetId');

//     const time = Date.now() + 1000 * 50 * 1;

//     if (Date.parse(expires) < time) {
//         const response = await fetch(`${BASE_URL}/widget/${widgetId}/refresh`, {
//             method: 'GET',
//             headers: {
//                 'Authorization': `Bearer ${refresh}`,
//             },
//         });
//         if (!response.ok) {
//             console.log(response);
//             const error = await response.json();
//             throw new Error(error?.message);
//         }
//         const data = await response.json();
//         localStorage.setItem('token', data?.data?.token);
//         localStorage.setItem('expires', data?.data?.expires);
//         localStorage.setItem('refresh', data?.data?.refresh);
//         localStorage.setItem('refresh_expires', data?.data?.refresh_expires);
//     }
// }