import { useCallback, useEffect, useMemo, useState } from 'react';
import { darken, lighten } from '@mui/material/styles';

import { useHttp } from './hooks';

import { MuiThemeProvider } from './MUI';

import { Main } from './components/Main/Main';

import getLang from './utils/getLang';

import { socket } from './index';

export const currentLang = getLang();

export const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const App = ({
  widgetId,
  token,
  isWidgetBlocked,
  setIsTokenChanged,
}) => {
  const [data, request] = useHttp(`widget/${widgetId}`);
  const [reload, setReload] = useState(false);
  const [showWidget, setShowWidget] = useState(true);

  const getUserTheme = useCallback(
    (theme) => ({
      shape: {
        window: data?.data?.rounding?.window,
        button: data?.data?.rounding?.button,
      },
      typography: {
        ...theme?.typography,
        fontFamily: data?.data?.font,
      },
      palette: {
        ...theme?.palette,
        primary: {
          ...theme?.palette?.primary,
          main: data?.data?.colors?.window?.background_message,
          light: lighten(data?.data?.colors?.window?.background_message, 0.8),
          text: data?.data?.colors?.window?.content,
          background: data?.data?.colors?.window?.background,
        },
        header: {
          ...theme?.palette?.header,
          main: data?.data?.colors?.header_and_copyright?.background,
          text: data?.data?.colors?.header_and_copyright?.content,
          light: lighten(
            data?.data?.colors?.header_and_copyright?.background,
            0.8
          ),
        },
        button: {
          ...theme?.palette?.button,
          main: data?.data?.colors?.button?.background,
          icon: data?.data?.colors?.button?.icon,
        },
        text: {
          ...theme?.palette?.text,
          dark: data?.data?.colors?.window?.content,
        },
        grey: {
          ...theme?.palette?.grey,
          dark: data?.data?.colors?.window?.content,
        },
      },
      components: {
        ...theme?.components,
        MuiCircularProgress: {
          ...theme?.components?.MuiCircularProgress,
          styleOverrides: {
            root: {
              ...theme?.components?.MuiCircularProgress?.styleOverrides?.root,
              color: data?.data?.colors?.header_and_copyright?.background,
            },
          },
        },
        MuiButton: {
          ...theme?.components?.MuiButton,
          styleOverrides: {
            root: {
              ...theme?.components?.MuiButton?.styleOverrides?.root,
              borderRadius: data?.data?.rounding?.button,
              backgroundColor:
                data?.data?.colors?.header_and_copyright?.background,
              color: data?.data?.colors?.button?.icon,
              '&:hover': {
                backgroundColor: darken(
                  data?.data?.colors?.header_and_copyright?.background,
                  0.2
                ),
              },
            },
          },
        },
        MuiTypography: {
          ...theme?.components?.MuiTypography,
          styleOverrides: {
            root: {
              ...theme?.components?.MuiTypography?.styleOverrides?.root,
              color: data?.data?.colors?.window?.content,
            },
          },
        },
        MuiButtonBase: {
          ...theme?.components?.MuiButtonBase,
          styleOverrides: {
            root: {
              ...theme?.components?.MuiButtonBase?.styleOverrides?.root,
              color: data?.data?.colors?.window?.content,
            },
          },
        },
        MuiChip: {
          ...theme?.components?.MuiChip,
          styleOverrides: {
            root: {
              ...theme?.components?.MuiChip?.styleOverrides?.root,
              color: data?.data?.colors?.window?.content,
              borderColor: data?.data?.colors?.window?.content,
            },
          },
        },
      },
    }),
    [data]
  );

  const margins = data?.data?.margins
    ? Object.values(data?.data?.margins)
    : [20, 20];

  const widgetRequest = useCallback(
    () =>
      request({
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    [token]
  );

  useEffect(() => {
    const step = 5 * 60 * 1000;
    const timeout = setInterval(() => {
      setReload((prev) => !prev);
    }, step);

    return () => setInterval(timeout);
  }, []);

  useEffect(() => {
    if (widgetId && token) {
      widgetRequest();
      setShowWidget(true);
    }
  }, [token, isWidgetBlocked, reload]);

  useEffect(() => {
    if (socket) {
      socket.on('site.widget.settings', widgetRequest);

      return () => {
        socket.off('site.widget.settings');
      };
    }
  }, [socket]);

  useEffect(() => {
    if (data?.data) {
      localStorage.setItem('user_settings', JSON.stringify(data));
    }
  }, [data?.data]);

  const appLang = data?.data?.languages?.find((lang) => lang === currentLang);

  if (!data?.data || isWidgetBlocked) return null;

  return (
    <MuiThemeProvider getUserTheme={getUserTheme}>
      <Main
        defaultLang={appLang || data?.data?.default_language}
        setIsTokenChanged={setIsTokenChanged}
        requestedFields={data?.data?.visitor_data_request}
        titleMessages={data?.data?.default_messages}
        position={data?.data?.position}
        copyDisabled={data?.data?.hide_copy}
        showWidget={showWidget}
        margins={margins}
        radius={data?.data?.rounding?.window}
        schedule={data?.data?.schedule}
        token={token}
        timeout={data?.data?.timeout}
        name={data?.data?.name}
        logotype={data?.data?.logotype}
        channels={data?.data?.channels}
        visitorMessages={data?.data?.visitor_messages}
      />
    </MuiThemeProvider>
  );
};
