import React, { useEffect, useState, useMemo, useCallback, memo } from 'react';

import {
  Box,
  Typography,
  Chip,
  Button,
  CircularProgress,
  Tooltip,
} from '@mui/material';

import { Message } from '../../components/Message/Message';
import { Details } from '../../common/Details/Details';

import { scrollToElement } from '../../utils/scrollToElement';

import { socket } from '../../index';

import { translates } from '../../constants/translates';

import { ReactComponent as AnimatedDotsIcon } from '../../assets/icons/animatedDots.svg';

const chatBodyStyles = {
  pl: 2.86,
  pr: 0,
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
};

const chatDialogStyles = {
  overflowY: 'auto',
  flex: '1 0 100px',
  pr: 2.86,
  pt: 1.45,
  display: 'flex',
  flexDirection: 'column',
};

export const ChatBody = memo(
  ({
    defaultLang,
    messages,
    messagesPreload,
    hasPreloadedMessages,
    visitorMessages,
    sendMessageWithActiveDialog,
    showRequest,
    isChatActive,
    dialogId,
    messagesLoading,
    isDialogActive,
    titleMessages,
    sendMessage,
    onClick = () => {},
    operatorsList = [],
  }) => {
    const [isCompanionWriting, setIsCompanionWriting] = useState(false);
    const [messageText, setMessageText] = useState('');

    useEffect(() => {
      if (messageText) {
        sendMessageWithActiveDialog(sendMessageHandler);
      }
      // return () => setMessageText('');
    }, [messageText, dialogId]);

    useEffect(() => {
      const bodyElement = document.querySelector('.chatBody');
      const selectedElementClasses = bodyElement?.lastChild.classList;
      const lastBodyElement =
        selectedElementClasses[selectedElementClasses.length - 1];
      scrollToElement(lastBodyElement);
    }, [messages, isCompanionWriting, isChatActive, showRequest]);

    useEffect(() => {
      socket.on('dialog.printing.get', (data) => {
        setIsCompanionWriting(data.status === 'start');
      });
    }, [isChatActive]);

    const sortedMessages = useMemo(() =>
      messages.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
    );

    const sendMessageHandler = useCallback(
      (id) => {
        sendMessage(messageText, id);
        setMessageText('');
      },
      [messageText]
    );

    return (
      <Box sx={chatBodyStyles} id="widget-body">
        <Box sx={chatDialogStyles} className="chatBody">
          {visitorMessages && !messages?.length && !isDialogActive ? (
            <DialogStartScreen
              titleMessages={titleMessages}
              visitorMessages={visitorMessages}
              defaultLang={defaultLang}
              onSendStartMessage={(text) => {
                setMessageText(text);
              }}
            />
          ) : (
            <>
              {/* {messagesLoading && (
                <CircularProgress
                  thickness={4}
                  size="100px"
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                  }}
                />
              )} */}
              {hasPreloadedMessages && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mt: '2px',
                    mb: '4px',
                    color: 'primary.text',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={messagesPreload}
                >
                  {translates[defaultLang].show_history}
                </Box>
              )}
              {sortedMessages?.map((message, index, arr) => (
                <Message
                  key={message.id}
                  defaultLang={defaultLang}
                  className="message"
                  message={message}
                  prevMessage={arr[index - 1]}
                  isMine={!message?.user}
                  isSingle={arr[index + 1]?.user?.id === message?.user?.id}
                />
              ))}
              {isCompanionWriting && <Typing defaultLang={defaultLang} />}
              {!!operatorsList?.length && (
                <ArntFreeOperanors defaultLang={defaultLang} />
              )}
              {showRequest && (
                <ActionBlock onClick={onClick} defaultLang={defaultLang} />
              )}
              <Box sx={{ height: '0px' }} />
            </>
          )}
        </Box>
      </Box>
    );
  }
);

const DialogStartScreen = ({
  titleMessages,
  defaultLang,
  onSendStartMessage,
  visitorMessages,
  inSchedule = true,
}) => {
  const [isMessageSended, setMessageSended] = useState(false);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: !inSchedule ? 'center' : 'left',
          height: '100%',
        }}
      >
        {!inSchedule ? (
          <>
            <Typography
              fontSize={16}
              color={'header.main'}
              sx={{
                mb: 1.45,
              }}
            >
              {titleMessages?.work?.greeting[defaultLang]}
            </Typography>
            <Typography
              sx={{
                mb: 1.9,
                fontSize: '14px',
              }}
            >
              {titleMessages?.non_work?.greeting[defaultLang]}
            </Typography>
          </>
        ) : (
          <>
            <Typography
              fontSize={16}
              color={'header.main'}
              typography="h4"
              sx={{
                mb: 1.45,
              }}
            >
              {titleMessages?.work?.greeting[defaultLang]}
            </Typography>
            <Typography
              sx={{
                mb: 2.9,
              }}
            >
              {titleMessages?.work?.result[defaultLang]}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '10px',
                maxHeight: '200px',
                overflowY: 'auto',
              }}
            >
              {visitorMessages.map(({ button, message }) => {
                if (!message) return null;

                const messageByLang = message[defaultLang];
                const buttonTextByLang = button[defaultLang];
                return (
                  <Chip
                    key={button + Math.random()}
                    label={buttonTextByLang}
                    variant="outlined"
                    text={messageByLang}
                    onClick={() => {
                      if (isMessageSended) return;
                      onSendStartMessage(messageByLang);
                      setMessageSended(true);
                    }}
                  />
                );
              })}
            </Box>
          </>
        )}
      </Box>
      {/* <Details title={'Напишите нам в мессенджер'}>
          <Messengers />
        </Details> */}
    </>
  );
};

// export const Messengers = ({
//   messengers = [
//     {
//       type: 'Viber',
//       name: 'viber://chat?number=+380123456789',
//     },
//     {
//       type: 'Telegram',
//       name: 'https://t.me/username',
//     },
//     {
//       type: 'Facebook',
//       name: 'https://www.facebook.com/username',
//     },
//     {
//       type: 'Instagram',
//       name: 'https://www.instagram.com/username',
//     },
//   ],
// }) => (
//   <Box
//     sx={{
//       display: 'flex',
//       flexWrap: 'wrap',
//       gap: '10px 28px',
//       alignItems: 'center',
//       maxHeight: '200px',
//       overflowY: 'auto',
//     }}
//   >
//     {messengers.map((messenger) => (
//       //  <Tooltip placement="top" title={`Contact with as in ${messenger.name}`}>
//       <Box
//         component={'a'}
//         sx={{
//           display: 'flex',
//         }}
//         key={messenger.type}
//         href={messenger.name}
//         target="_blank"
//         rel="noreferrer"
//       >
//         <Box
//           component={getIcon(messenger.type)}
//           sx={{
//             width: '26px',
//             height: '26px',
//             cursor: 'pointer',
//           }}
//         />
//       </Box>
//       //  </Tooltip>
//     ))}
//   </Box>
// );

export const Typing = memo(
  ({ defaultLang, text = translates[defaultLang].writing }) => (
    <Typography
      sx={{
        display: 'flex',
        alignItems: 'center',
        mt: 'auto',
        mb: 1.4,
        columnGap: '8px',
        color: 'header.main',
        fontWeight: 'bold',
      }}
    >
      {text}{' '}
      <Box
        component={AnimatedDotsIcon}
        sx={{
          height: '8px',
        }}
      />
    </Typography>
  )
);

const ArntFreeOperanors = ({ defaultLang }) => (
  <Box
    sx={(theme) => ({
      padding: theme.spacing(1.45),
      margin: '10px auto',
      borderRadius: '5px',
      border: `1px solid ${theme.palette.error.alternate}`,
    })}
  >
    {translates[defaultLang].no_free_operators}
  </Box>
);

const ActionBlock = ({ onClick, defaultLang }) => (
  <Box
    sx={(theme) => ({
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: theme.palette.primary.text,
      borderRadius: '5px',
      margin: 'auto auto 10px',
      padding: theme.spacing(1.45),
      border: `1px solid ${theme.palette.primary.main}`,
      typography: 'h5',
    })}
  >
    {translates[defaultLang].give_us_your_data}
    <Button
      onClick={onClick}
      variant="contained"
      sx={{ typography: 'subtitle1' }}
    >
      {translates[defaultLang].fill}
    </Button>
  </Box>
);
