import { stringToColor } from '.';

const stringAvatar = (name, sx) => ({
    sx: {
        bgcolor: stringToColor(name),
        ...sx
    },
    children: `${name.split(' ')[0][0]}`,
});

export default stringAvatar;
